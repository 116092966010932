.videoBackground{
    position: fixed;
    z-index: 9;
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255);
}

.videoBackgroundImage{
    position: fixed;
    width: 1920px;
    height: 1200px;

}



.videoContainer{
    width: 500px;
    height: 600px;
    background-color: #fff;
    z-index: 3;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    top:10%;
    border-radius:25px;
    border: 4px solid #1a7da3;
    box-shadow: 3px 5px #587682;

}


.videoLogo{
    position: absolute;
    width: 360px;
    min-width: 360px;
    height: 120px;
    top: 28px;
    left: 70px;
}


.embeddedVideo {
    position: absolute;
    padding: 10px;
    top: 175px;
    left: 12%;
}


.enterButton button{
    background-color: #d95f0e;
    color: #fff;
    width: 20%;
    height: 50px;
    position: absolute;
    bottom: 30px;
    left: 40% ;
    font-size: 20px;
    font-weight: bold;

}

.enterButton button:hover {
    background-color: #c63e08;
}

@media only screen and  (max-width: 700px) {
    .videoBackground{
        position: fixed;
        z-index: 9;
        width: 100%;
        height: 100%;
        background: rgb(255, 255, 255);
    }

    .videoBackgroundImage{
        position: fixed;
        width: 1920px;
        height: 1200px;

    }



    .videoContainer{
        width: 355px;
        height: 500px;
        background-color: #fff;
        z-index: 3;
        position: relative;
        display: block;
        margin-left: auto;
        margin-right: auto;
        top:5%;
        border-radius:25px;
        border: 4px solid #1a7da3;
        box-shadow: 3px 5px #587682;

    }


    .videoLogo{
        position: absolute;
        width: 180px;
        min-width: 180px;
        height: 60px;
        top: 28px;
        left: 90px;
    }


    .embeddedVideo {
        position: absolute;
        padding: 0px;
        top: 120px;
        left: 0%;
        max-width: 300px;
    }


    .enterButton button{
        background-color: #d95f0e;
        color: #fff;
        width: 30%;
        height: 50px;
        position: absolute;
        bottom: 30px;
        left: 35% ;
        font-size: 20px;
        font-weight: bold;

    }

    .enterButton button:hover {
        background-color: #c63e08;
    }
}