.btn {
    text-align: center;
    position: absolute;
    top: 55px;
    left: 5px;
    height: auto;

    /*zoom: 1.5;*/
}
.tutorialBtn {
    text-align: center;
    position: absolute;
    top: 536px;
    left: 5px;
    min-height: 70px;
    max-height: 70px;
    min-width: 110px;
    max-width: 110px;
}

button {
    /*border-radius: 0px;*/
    background-color: #fff;
    padding: 5px;
    font-size: 12px;
    width: 110px;

}

button:hover {
    background-color: #8c96c6;
}



.modal_content {
    position: absolute;
    height: 100%;
    width: 100%;
}


.tip {
    background-color: #49454c;
    border: 3px solid #49454c;
    border-radius: 15px;
    color: #fff;
    padding: 5px;
    position: absolute;
    height: auto;
    text-align: center;
    font-family: "Verdana", Geneva, sans-serif;
    font-weight: 700;

}


#choroplethTip{
    width:200px;
    top: 30%;
    right:30%;
}

.tip.arrow-choropleth{
    margin-top: 20px;
}

.tip.arrow-choropleth:after{
    position: absolute;
    content: " ";
    left:-15px;
    top: 25px;
    border-right: 15px solid #49454c;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
}


#selectCityTip{
    width: 250px;
    top: 65px;
    left: 170px;

}
.tip.arrow-selectCity{
    margin-top: 10px;
}

.tip.arrow-selectCity:after{
    position: absolute;
    content: " ";
    left: 120px;
    top: -15px;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid #49454c;
}



#searchTip{
    width: 300px;
    right: 270px;
    top: 10px;
}
.tip.arrow-search{
    margin-top: 0px;
}

.tip.arrow-search:after{
    position: absolute;
    content: " ";
    right: -15px;
    top: 5px;
    border-right: None;
    border-left: 15px solid #49454c;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
}

#zoomTip{
    width: 200px;
    right: 53px;
    top: 55px;
}

.tip.arrow-zoom{
    margin-top: 0px;
}

.tip.arrow-zoom:after{
    position: absolute;
    content: " ";
    right: -15px;
    top: 5px;
    border-right: None;
    border-left: 15px solid #49454c;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
}

#landuseTip{
    width: 110px;
    right: 220px;
    top: 320px;
}
.tip.arrow-landuse{
    margin-top: 0px;
}

.tip.arrow-landuse:after{
    position: absolute;
    content: " ";
    right: -15px;
    top: 10px;
    border-right: None;
    border-left: 15px solid #49454c;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
}


#sliderTip{
    width: 350px;
    right: 40%;
    bottom: 102px ;
}

.tip.arrow-slider{
    margin-bottom: 10px;
}

.tip.arrow-slider:after{
    position: absolute;
    content: " ";
    right: 160px;
    bottom: -30px;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-top: 15px solid #49454c;
    border-bottom: 15px solid transparent;
}


@media only screen and (max-width: 700px) {
    .btn {
        text-align: center;
        position: absolute;
        top: 55px;
        left: 5px;
        height: auto;

    }
    .tutorialBtn {
        text-align: center;
        position: absolute;
        top: 518px;
        left: -55px;
        min-height: 0px;
        max-height: 0px;
        min-width: 0px;
        max-width: 0px;
    }

    button {
        /*border-radius: 0px;*/
        background-color: #fff;
        padding: 0px;
        font-size: 12px;
        width: 0px;

    }

}







