#city_menu_container {
    position: absolute;
    left: 300px;
    top: 0px;
    width: 250px;
    /*background-color: #fff;*/
    /*border: 2px solid #5d5f64;*/
}


#city_title{
    text-transform: capitalize;
    position: relative;
    font-size: 22px;
    color:#fc8d62;
    padding: 0px;
    margin-top:5px;
    left: 7%;
}

#subtitle{
    position: absolute;
    left: 180px;
    top: 5px;
    width: 250px;
    font-size: 10px;
    line-height: 15px;
    color:#cf714c;
    font-weight: bold;
    padding-left: 3px;
}




.dropbtn {
  background-color: #fff;
  color: #2a2a2a;
  text-decoration: underline;
  padding: 6px;
  font-size: 12px;
  border: none;
  cursor: pointer;
  width: 100px;
}

.dropdown {
  position: relative;
  display: inline-block;
}


.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 190px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  
}

.dropdown-content button {
  color: black;
  padding: 5px 16px;
  text-decoration: none;
  display: block;
  text-transform: capitalize;
    border: none;
    background: none;
    text-align: left;
    width: 100%
}

.dropdown-content button:hover {
    background-color: #8da0cb
}

.dropdown:hover .dropdown-content {
    display: block;
    }

.dropdown:hover .dropbtn {
    background-color: #d3d3d3;
    }


@media only screen and  (max-width: 700px) {

    #city_menu_container {
        /*position: absolute;*/
        left: 100px;
        top: 0px;
        width: 250px;
        position: relative;
        z-index: 10;
        /*background-color: #fff;*/
        /*border: 2px solid #5d5f64;*/
    }


    #city_title{
        text-transform: capitalize;
        position: relative;
        font-size: 14px;
        color:#fc8d62;
        padding: 0px;
        margin-top:0px;
        left: 5px;
    }

    #subtitle{
        position: absolute;
        left: 95px;
        vertical-align: center;
        width: 180px;
        font-size: 8px;
        line-height: 12px;
        color:#cf714c;
        font-weight: bold;
        padding-left: 3px;
    }




    .dropbtn {
        background-color: #fff;
        color: #2a2a2a;
        text-decoration: underline;
        padding: 6px;
        font-size: 9px;
        border: none;
        cursor: pointer;
        width: 80px;
    }

    .dropdown {
        position: relative;
        display: inline-block;
    }


    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 100px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;

    }

    .dropdown-content button {
        color: black;
        padding: 5px 16px;
        text-decoration: none;
        display: block;
        text-transform: capitalize;
        border: none;
        background: none;
        text-align: left;
        width: 100%
    }

    .dropdown-content button:hover {
        background-color: #8da0cb
    }

    .dropdown:hover .dropdown-content {
        display: block;
    }

    .dropdown:hover .dropbtn {
        background-color: #d3d3d3;
    }

}