

.cityViewBackground{
    background: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
}
.cityViewLogo{
    position: absolute;
    width: 360px;
    min-width: 360px;
    height: 120px;
    top: 28px;
    left: 110px;
}

.cityViewContainer{
    width: 600px;
    height: 750px;
    /*overflow: hidden;*/
    background-color: #fff;
    z-index: 3;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    top:10%;
    border-radius:25px;
    border: 4px solid #1a7da3;
    box-shadow: 3px 5px #587682;
}

.cityViewHeader{
    font-family: FreeSans;
    left: 100px;
    top: 180px;
    position: relative;
    z-index: 10;
    font-size: 20px;
    font-weight: bold;
    background-color: #fff;
    width: 350px;
    height: 25px;
    text-align: center;

}

.cityItemContainer{
    top: 25%;
    left: 100px;
    height: 520px;
    width:400px;
    position: absolute;
    font-family: FreeSans;
    overflow: auto;
}


.cityViewItem{
    padding: 5px;
    margin-bottom: 5px;

}

.cityViewItem a {
    color: #fd8805;
}
.cityViewItem a:hover {
    color: #d95f0e;

}

.cityItemSubtitle{
    padding: 0px;
    margin-top: -10px;
    color: #09739c;

}




@media only screen and  (max-width: 700px) {

    .cityViewBackground{
        background: #fff;
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .cityViewLogo{
        position: absolute;
        width: 180px;
        min-width: 180px;
        height: 60px;
        left: 80px;

    }

    .cityViewContainer{
        width: 355px;
        height: 550px;
        /*overflow: hidden;*/
        background-color: #fff;
        z-index: 3;
        position: relative;
        display: block;
        margin-left: auto;
        margin-right: auto;
        top:10%;
        border-radius:25px;
        border: 4px solid #1a7da3;
        box-shadow: 3px 5px #587682;
    }

    .cityViewHeader{
        font-family: FreeSans;
        left: 15px;
        top: 100px;
        position: relative;
        z-index: 10;
        font-size: 20px;
        font-weight: bold;
        background-color: #fff;
        width: 300px;
        height: 25px;
        text-align: center;

    }

    .cityItemContainer{
        top: 25%;
        left: 10px;
        height: 400px;
        width:340px;
        position: absolute;
        font-family: FreeSans;
        overflow: auto;
    }


    .cityViewItem{
        padding: 5px;
        margin-bottom: 5px;

    }

    .cityViewItem a {
        color: #fd8805;
    }
    .cityViewItem a:hover {
        color: #d95f0e;

    }

    .cityItemSubtitle{
        padding: 0px;
        margin-top: -10px;
        color: #09739c;

    }



}