.bottomBar{
    position: absolute;
    z-index: 10;
    width: 490px;
    height: 20px;
    bottom: 0px;
    right: 0px;
    background-color: #fff;

}

.bottomLink a{
    position: absolute;
    margin-left: 10px;

}
.bottomLink a:link{
    color: #000;
}
.bottomLink a:visited{
    color: #000;

}
.bottomLink a:hover{
    color: #8da0cb;
}

.rsfLink {
    position: absolute;
    left: 180px;

}
.rsfLink a:link{
    color: #000;
}
.rsfLink a:visited{
    color: #000;

}
.rsfLink a:hover{
    color: #8da0cb;
}




.cptrLink {
    position: absolute;
    left: 5px;
}

.cptrLink a:link{
    color: #000;
}
.cptrLink a:visited{
    color: #000;

}
.cptrLink a:hover{
    color: #8da0cb;
}


.userLink {
    position: absolute;
    right: 60px;
}

.userLink a:link{
    color: #000;
}
.userLink a:visited{
    color: #000;

}
.userLink a:hover{
    color: #8da0cb;
}


.sourcesLink {
    position: absolute;
    right: 5px
}

.sourcesLink a:link{
    color: #000;
}
.sourcesLink a:visited{
    color: #000;

}
.sourcesLink a:hover{
    color: #8da0cb;
}

@media only screen and (max-width:700px){
    .bottomBar{
        position: absolute;
        z-index: 10;
        width: 0px;
        height: 20px;
        bottom: 0px;
        left: -100px;
        background-color: #fff;

    }

    .bottomLink a{
        position: absolute;
        margin-left: 0px;

    }
    .bottomLink a:link{
        color: #000;
    }
    .bottomLink a:visited{
        color: #000;

    }
    .bottomLink a:hover{
        color: #8da0cb;
    }

    .rsfLink {
        position: absolute;
        left: 0px;

    }
    .rsfLink a:link{
        color: #000;
    }
    .rsfLink a:visited{
        color: #000;

    }
    .rsfLink a:hover{
        color: #8da0cb;
    }




    .cptrLink {
        position: absolute;
        left: 5px;
    }

    .cptrLink a:link{
        color: #000;
    }
    .cptrLink a:visited{
        color: #000;

    }
    .cptrLink a:hover{
        color: #8da0cb;
    }


    .userLink {
        position: absolute;
        right: 60px;
    }

    .userLink a:link{
        color: #000;
    }
    .userLink a:visited{
        color: #000;

    }
    .userLink a:hover{
        color: #8da0cb;
    }


    .sourcesLink {
        position: absolute;
        right: 5px
    }

    .sourcesLink a:link{
        color: #000;
    }
    .sourcesLink a:visited{
        color: #000;

    }
    .sourcesLink a:hover{
        color: #8da0cb;
    }
}