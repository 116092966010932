@media only screen and (min-width: 1050px) {
    body {
        margin: 0;
        min-width: 1050px;
        /*min-width: 400px;*/
    }
}

@media only screen and  (max-width: 900px) {
    body{
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        margin: 0;
    }
}