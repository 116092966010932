table,
th,
td {
    border: 1px solid darkblue;
    border-collapse: collapse;
}


.reportLink{
    height: 20px;
    width: auto;
    position: absolute;
    right: 35px;
    top: 390px;
    background-color: #fff;
    padding: 5px;
    font-family: "Arial", sans-serif;
    font-size: 13px;
    color: #5d5f64;

}

.reportLink a:link{
    color: #000;
}
.reportLink a:visited{
    color: #000;

}
.reportLink a:hover{
    color: #8da0cb;
 }





button:hover {
    background-color: #8c96c6;
}


@media only screen and  (max-width: 700px) {
    .reportLink{
        height: 15px;
        width: 120px;
        position: absolute;
        left: 5px;
        top: 540px;
        text-align: center;
        background-color: #fff;
        padding: 5px;
        font-family: "Arial", sans-serif;
        font-size: 10px;
        color: #5d5f64;
        display: none;
    }

    .reportLink a:link{
        color: #000;
    }
    .reportLink a:visited{
        color: #000;

    }
    .reportLink a:hover{
        color: #8da0cb;
    }

    button:hover {
        background-color: #8c96c6;
    }

     .mapboxgl-ctrl{
         position: fixed;
         top: 50px;
         left: 90px;
    }

     /*.mapboxgl-ctrl .mapboxgl-ctrl-group{*/
     /*    position: absolute;*/
     /*    left: 50px;*/
     /*}*/

    .mapboxgl-ctrl-zoom-in{
        position: absolute ;
        /*float: right;*/
        /*left: 0px;*/
    }

    .mapboxgl-ctrl .mapboxgl-ctrl-logo{
         width:0px;
         left: -100px;
     }

    .mapboxgl-ctrl-attrib a{
        position: absolute;
        width: 0px;
        left: -200px;
        color: rgba(0,0,0,0.0);
    }


    /*.mapboxgl-ctrl-top-right .mapboxgl-ctrl*/
    /*{}*/

    /*.mapboxgl-ctrl .mapboxgl-ctrl-group{*/
    /*    position: absolute;*/
    /*    left: -50px;*/
    /*}*/

    .mapboxgl-popup {
        position: relative;
    }

    .mapboxgl-popup-close-button {
        font-size: 24px;
      }

}