.popup {
    height: 300px;
    overflow-y: scroll;
    position: relative;

}

.mapboxgl-popup-close-button {
    font-size: 16px;
    position: absolute;
    z-index: 100;
  }

.popupTitle {
    background-color: #fc8d62;
    color: #fff;
    font-family: "Arial Black", sans-serif;
    left: 0;
    right: 0;
    top: 0;
    font-size: 1.5em;
    padding: 0.4em;
    text-align: center;
}

.sectionTitle {
    color: #5d5f64;
    font-family: "Arial Black", sans-serif;
    left: 0;
    right: 0;
    top: 0;
    font-size: 1.2em;
    padding: 0.5em;
    text-align: center;
}

@media only screen and (max-width: 700px ){
    .mapboxgl-popup-close-button {
        font-size: 20px;
        left: 200px;
        position: absolute;
      }
}