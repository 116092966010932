#choropleth_menu_container {
    position: absolute;
    right: 10px;
    top: 160px;
    min-width: 150px;
    min-height: 250px;
    padding: 10px;
    background-color: #fff;
    border-style: solid;
    border-color: #808080;
    border-width: 0;
    box-shadow: 2px 2px;
}

#title {
    position: relative;
    font-size: 16px;
    color: #fc8d62;
    padding: 3px;
    margin-top: 10px;
    left: 0%;
}

#land_use {
    font-size: 14px;
}

@media only screen and  (max-width: 700px) {
    #choropleth_menu_container {
        position: absolute;
        left: -5px;
        top: 325px;
        /*max-width: 15px;*/
        width: 10px;
        min-width: 126px;
        min-height: 180px;
        height: auto;
        padding: 0px;
        background-color: #fff;

    }

    #title {
        position: relative;
        font-size: 10px;
        color: #fc8d62;
        padding: 0px;
        margin-top: 10px;
        left: 5px;
    }

    #land_use {
        font-size: 10px;
        line-height: 10px;
    }

    input[type=radio] {
        border: 0px;
        width: 10%;
        height: 10px;
    }
}