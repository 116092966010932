.session {
    margin-bottom: 30px;
    left: 25%;
    right: 25%;
    background-color: #8da0cb;
}

.slider_row {
    height: 12px;
    width: 95%;
    margin: 5px;
}

h2#slider-label {
    display: inline-block;
    margin: 10px;
    font-size: 1.2em;
    color: #fff;
}

#sliderbar{
    position:absolute;
    bottom: 0%;
    right: 25%;
    left: 25%;
}

@media only screen and (max-width: 700px ){
    .session {
        bottom: 30px;
        height: 65px;
        left: 0%;
        right: 0%;
        background-color: #8da0cb;
    }

    .slider_row {
        height: 8px;
        width: 300px;
        margin: 5px;
        left: 10px;
    }

    h2#slider-label {
        display: inline-block;
        margin: 10px;
        font-size: 1em;
        color: #fff;
    }

    #sliderbar{
        position:absolute;
        bottom: 0%;
        width: 350px;
        left: 10px;
    }

}