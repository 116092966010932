
.titleBar{
    position: absolute;
    left: 0px;
    top:0px;
    text-align: left;
    width: 100%;
    height: 32px;
    color: #5d5f64;
    background-color: #fff;
    font-size:3em;
    font-family: FreeSans;
    padding: 10px;
    padding-top: 15px;
    /*border: 2px solid #5d5f64;*/
}


.tseLogo {
    height:40px;
    width: 120px;
    position: absolute;
    left: 167px;
    top: 7px;
}

.logo {
    height: 70px;
    width:70px;
    position: relative;
    z-index: 1;
    left: 37.5px;
    top: -15px;
}

.titleSubText{
    font-size: 10px;
    color: grey;
    margin-left: 290px;
    margin-top:  5px;
    width: 130px;
    height: auto;
    text-align: right;
    line-height: 11px;
    z-index: 1;
    position: relative;
}

#legend {
    position: absolute;
    padding: 10px;
    top: 55px;
    left: 0px;
    height: 459px;
    background-color: #fff;
    opacity: 1;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    line-height: 18px;
    margin-bottom: 40px;
    width: 145px;
    /*border: 2px solid #5d5f64;*/

}


.legend_col {
    height: 100%;
    width: 50%;
    top: 0px;
    float: right;
    margin-right: 5px;
    margin-left: 0px
}

#noData {
    color: #000;
    background-color: #000;
    position: absolute;
    width: 20%;
    height: 10px;
    top: 241px;
    padding: 1px;
}

.row {
    height: 12px;
    width: 100%;
    font-size: 10px;
    padding: 1.3px;
}

.p_row {
    height: 16px;
    width: 100%;
    font-size: 10px;
    padding: 1.3px;
}


p {
    font-size: 9px;
    margin: 1px;
    text-align: right;
}

h4 {
    font-size: 11px;
}

.title_container {
    width: 80px;
}

.title {
    width: 80px;
    float: left;
    text-align: left;
}

#parcel_title {
    position: absolute;
    top: 245px;
}

#parcel_legend {
    position: absolute;
    top: 285px;
    line-height: 18px;
    height: 90px;
    margin-bottom: 0px;
    width: 130px;
}

.parcel_text {
    font-size: 12px;
    padding-right: 5px;
}

.dot {
    position: absolute;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;
    float: right;
}
.dot.residential {
    top: 4px;
    background-color: #fe9929;
}

.dot.commercial {
    top: 23px;
    background-color: #8da0cb;
}

.dot.mixed_use {
    top: 42px;
    background-color: #bc8dcb;
}

.dot.industrial {
    top: 61px;
    background-color: #808865;
}

.dot.vacant {
    top: 80px;
    background-color: #d4b78a;
}

.dot.fully_exempt {
    top: 98px;
    background-color: #05ddcb;
}

.dot.other {
    top: 117px;
    background-color: #d92550;
}

#exempt_dot {
    position: absolute;
    height: 7px;
    width: 7px;
    top: 139px;
    left: 4px;
    background-color: #000;
    border-radius: 50%;
    display: inline-block;
}

#abated_dot {
    position: absolute;
    height: 5px;
    width: 5px;
    top: 159px;
    left: 4px;
    background-color: #fff;
    border: 1px solid black;
    border-radius: 50%;
    display: inline-block;
}

#color_grad {
    background-image: linear-gradient( #993404, #d95f0e, #fe9929, #fec44f, #fee391, /*                    #ffffd4,*/
    #fff, /*                    #edf8fb, */
    #bfd3e6, #9ebcda, #8c96c6, #8856a7, #810f7c);
    position: absolute;
    top: 83px;
    padding: 1px;
    height: 150px;
    margin-bottom: 40px;
    width: 20%;
}

.tooltip {
    position: relative;
    display: inline-block;
}

#median_tax_tooltip {
    position: absolute;
    top: 22px;
    left: 9px;
    height: 14px;
    width: 14px;
}

#parcel_tooltip {
    position: absolute;
    top: 3px;
    left: 0px;
    height: 14px;
    width: 14px;
}

#parcel_type_tooltip {
    position: absolute;
    top:-11px;
    left: 7px;
    height: 14px;
    width: 14px;
}


.tooltip .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: #8da0cb;
    /*background-color:#555;*/
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    left: 15px;
    top: -50px;
    margin-left: 0px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

/*##################################################################################################3*/
/*##################################################################################################3*/


@media only screen and (max-width: 700px){
    .titleBar{
        position: absolute;
        left: 0px;
        top:0px;
        text-align: left;
        width: 100%;
        height: 20px;
        color: #5d5f64;
        background-color: #fff;
        font-size:3em;
        font-family: FreeSans;
        padding: 10px;
        padding-top: 15px;
    }


    .tseLogo {
        height:30px;
        width: 90px;
        position: absolute;
        z-index: 10;
        left: 5px;
        top: 5px;
    }

    .logo {
        height: 0px;
        width:0px;
        position: absolute;
        z-index: 1;
        left: 0px;
        top: 2px;
    }

    .titleSubText{
        font-size: 10px;
        color: grey;
        margin-left: 290px;
        margin-top:  5px;
        width: 130px;
        height: auto;
        text-align: right;
        line-height: 11px;
        z-index: 1;
        position: relative;
    }

    #legend {
        position: absolute;
        padding: 10px;
        top: 28px;
        left: 0px;
        height: 245px;
        background-color: #fff;
        opacity: 1;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        line-height: 18px;
        margin-bottom: 40px;
        width: 50px;
        /*border: 2px solid #5d5f64;*/

    }


    .legend_col {
        height: 50%;
        width: 40%;
        top: 0px;
        float: right;
        margin-right: 0px;
        margin-left: 0px
    }

    #noData {
        color: #000;
        background-color: #000;
        position: absolute;
        width: 15px;
        height: 10px;
        top: 241px;
        font-size: 8px;
    }

    .row {
        height: 12px;
        width: 100%;
        font-size: 8px;
        padding: 0px;
    }

    .p_row {
        height: 16px;
        width: 100%;
        font-size: 10px;
        padding: 1px;
    }

    p {
        font-size: 8px;
        margin: 1px;
        /*text-align: right;*/
    }

    h4 {
        font-size: 11px;
    }

    .title_container {
        width: 80px;
    }

    .title {
        width: 50px;
        font-size: 8px;
        padding: 0px;
        float: left;
        text-align: center;

    }

    #parcel_title {
        position: absolute;
        top: -245px;
    }

    #parcel_legend {
        position: absolute;
        top: -285px;
        line-height: 18px;
        height: 90px;
        margin-bottom: 0px;
        width: 50px;
    }

    .parcel_text {
        font-size: 8px;
        /*text-align: left;*/
        left: 2px;
    }

    .dot {
        position: absolute;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        display: inline-block;
        float: right;
    }
    .dot.residential {
        top: 4px;
        background-color: #fe9929;
    }

    .dot.commercial {
        top: 23px;
        background-color: #8da0cb;
    }

    .dot.mixed_use {
        top: 42px;
        background-color: #bc8dcb;
    }

    .dot.industrial {
        top: 61px;
        background-color: #66c2a5;
    }

    .dot.vacant {
        top: 80px;
        background-color: #d4b78a;
    }

    .dot.other {
        top: 99px;
        background-color: #d92550;
    }

    #exempt_dot {
        position: absolute;
        height: 4px;
        width: 4px;
        top: 119px;
        left: 0px;
        background-color: #000;
        border-radius: 50%;
        display: inline-block;
    }

    #abated_dot {
        position: absolute;
        height: 4px;
        width: 4px;
        top: 140px;
        left: 0px;
        background-color: #fff;
        border: 1px solid black;
        border-radius: 50%;
        display: inline-block;
    }

    #color_grad {
        background-image: linear-gradient( #993404, #d95f0e, #fe9929, #fec44f, #fee391, /*                    #ffffd4,*/
        #fff, /*                    #edf8fb, */
        #bfd3e6, #9ebcda, #8c96c6, #8856a7, #810f7c);
        position: absolute;
        top: 87px;
        padding: 1px;
        height: 140px;
        margin-bottom: 40px;
        width: 20%;
    }

    .tooltip {
        position: relative;
        display: inline-block;
    }

    #median_tax_tooltip {
        position: absolute;
        top: 22px;
        left: 9px;
        height: 0px;
        width: 0px;
    }

    #parcel_tooltip {
        position: absolute;
        top: 3px;
        left: 9px;
        height: 0px;
        width: 0px;
    }

    .tooltip .tooltiptext {
        visibility: hidden;
        width: 300px;
        background-color: #8da0cb;
        /*background-color:#555;*/
        color: #fff;
        text-align: left;
        border-radius: 6px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        left: 15px;
        top: -50px;
        margin-left: 0px;
        opacity: 0;
        transition: opacity 0.3s;
    }

    .tooltip:hover .tooltiptext {
        visibility: visible;
        opacity: 1;
    }

}